import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import SignaturePad from "react-signature-canvas"

import numeral from "numeral";
import axios from "axios";
import toast from 'react-hot-toast';
import uuid from 'react-uuid';
import { RadioGroup, Transition } from "@headlessui/react";

import { urls } from "../../common";

import { FullQuote } from "../../types/quote";
import { FullCompany } from "../../types/company";
import Error from "../Errors";
import { FullUser } from "../../types/user";
import { businessTypes, industryOptions } from "../../common/options";
import EmailForm from "./EmailForm";
import LoadingDots from "../../components/LoadingDots";
import Button from "../../components/Button";
import { formatDate } from "../../common/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faBadgeCheck, faCheck, faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import CustomSelect from "../../components/form/CustomSelect";
import { Checkbox } from "../../components/form/Checkbox";
import Input from "../../components/form/Input";
import Label from "../../components/form/Label";
import TextArea from "../../components/form/Textarea";

type quoteProps = FullQuote & FullUser;
type pageProps = {
    admin?: boolean
}

const NewLineText = ({ text }: { text: string }) => {
    const paragraphs = text.split(/\n/g);
    return (
        paragraphs.map(x => (<p key={uuid()}>{x}</p>))
    )
}

const ViewQuote = ({ admin }: pageProps) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [signed, setSigned] = useState<boolean>(false);
    const [signature, setSignature] = useState<Blob>();
    const [agreed, setAgreed] = useState<boolean>(false);
    const [required, setRequired] = useState<boolean>(true);
    const [quote, setQuote] = useState<quoteProps>();
    const [company, setCompany] = useState<FullCompany>();
    const [total, setTotal] = useState<number>()
    const [finalTotal, setFinalTotal] = useState<number>()
    const [currentCommitment, setCurrentCommitment] = useState<any>()
    const [commitment, setCommitment] = useState<string>()
    const [additionalDays, setAdditionalDays] = useState<number>()

    const [signature_name, setSignatureName] = useState<string>()
    const [director_name, setDirectorName] = useState<string>()
    const [billing_name, setBillingName] = useState<string>()
    const [billing_email, setBillingEmail] = useState<string>()
    const [billing_telephone, setBillingTelephone] = useState<string>()
    const [billing_position, setBillingPosition] = useState<string>()

    const [hs_contact_name, setHSContactName] = useState<string>();
    const [hs_title, setHSTitle] = useState<string>();
    const [hs_email, setHSEmail] = useState<string>();
    const [hs_telephone, setHSTelephone] = useState<string>();

    const [payment, setPayment] = useState<string>()
    const { quoteID } = useParams();

    const wrapper = useRef<HTMLDivElement>(null);
    const [wrapperWidth, setWrapperWidth] = useState(1);
    const [currentStep, setCurrentStep] = useState(0);
    const [moving, setMoving] = useState("right");

    function handleResize() {
        if (wrapper.current !== null) {
            setWrapperWidth(wrapper.current.offsetWidth);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const MySwal = withReactContent(Swal)
    const signRef = useRef<any>({});

    useEffect(() => {
        axios.get(`${urls.remoteURL}your-quote/${quoteID}`)
            .then(({ data }) => {
                if (!data.quote.payment_options.onetime && !!data.quote.commitment) {
                    setCommitment(data.quote.commitment);
                    setCurrentCommitment(data.quote.payment_options.options?.filter((x: any) => x.id === data.quote.commitment)[0]);
                }

                setQuote(data.quote);
                setCompany(data.company);
                setPayment(!!data.quote.payment_options.onetime ? 'full' : data.quote.payment || 'monthly')
                setAdditionalDays(data.quote.additional_days || 0)
                setSigned(!!data.quote.signature);
                setLoading(false);
                setNotFound(false);

                setBillingEmail(data.quote.billing_email);
                setBillingName(data.quote.billing_name);
                setBillingPosition(data.quote.billing_position);
                setBillingTelephone(data.quote.billing_telephone);

                setHSContactName(data.quote.hs_contact_name);
                setHSTitle(data.quote.hs_title);
                setHSTelephone(data.quote.hs_telephone);
                setHSEmail(data.quote.hs_email);

                setDirectorName(data.quote.director_name);
                // updatePrices();
                handleResize();
            })
            .catch((e) => {
                console.error(e);

                setQuote(undefined);
                setCompany(undefined);
                setNotFound(true);
                setLoading(false);
            });


    }, [quoteID]);

    useEffect(() => {
        updatePrices();
        handleResize();
    }, [quote, commitment, additionalDays])

    const updatePrices = () => {
        if (!!quote) {

            let total: number = (quote?.discount_amount as number) > 0 ?
                (quote?.total as number) - (quote?.discount_amount as number) :
                (quote?.discount_percent as number) > 0 ?
                    quote?.total as number - (quote?.total as number * (quote?.discount_percent as number / 100)) :
                    quote.total as number;

            if (additionalDays as number > 0) { total += (additionalDays as number) * quote.additional_options.daysrate }
            const current_commitment = commitment && quote.payment_options.options ? quote.payment_options.options.filter((x: any) => x.id === commitment)[0] : null;
            const multlier = commitment && current_commitment ? current_commitment.months / 12 : 1;
            const rate = current_commitment?.rate || 1;

            setCurrentCommitment(current_commitment);
            setTotal(total);
            setFinalTotal((total * multlier) * rate);
        }
    }

    const handleSendQuote = () => {

        MySwal.fire({
            title: 'Send Quote',
            html: <EmailForm inputID="emailFormJSON" company={company} quote={quote} />,
            showCancelButton: true,
            confirmButtonText: 'Send',
            showLoaderOnConfirm: true,
            customClass: {
                htmlContainer: 'z-20'
            },
            preConfirm: () => {
                const data = JSON.parse((document.getElementById("emailFormJSON") as HTMLInputElement).value);
                data.id = company?.id;

                if (!data.name || !data.email) {
                    MySwal.showValidationMessage(
                        `Please fill in required fields!`
                    )
                    return;
                }

                return axios.post(`${urls.remoteURL}quotes/${quoteID}/send`, data).then((x) => {
                    return x.data
                }).catch((err) => {

                    MySwal.showValidationMessage(
                        err.response.data.message ?? `Problem Saving Student`
                    )
                });

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                toast.success('Quote emailed!')
            }
        })
    }

    const handleSignOfferClick = () => {
        if (admin) {
            alert('Viewing as admin.')
        } else {

            MySwal.fire({
                title: 'Sign here',
                html: <SignaturePad ref={signRef} canvasProps={{ className: `w-full border` }} />,
                showCancelButton: true,
                confirmButtonText: 'Save',
                showLoaderOnConfirm: true,
                customClass: {
                    htmlContainer: 'z-20'
                },
                preConfirm: () => {
                    if (signRef.current.isEmpty()) {
                        MySwal.showValidationMessage(
                            `Please Sign`
                        )
                        return;
                    }
                    const data = signRef.current.getTrimmedCanvas().toDataURL("image/png");
                    setQuote(prev => ({ ...prev, signature: data }));
                    setSignature(data);
                    setSigned(true);
                    return data;
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    toast.success('Signed');
                }
            })
        }
    }

    const handleAcceptOfferClick = () => {
        if (admin) {
            alert('Viewing as admin.')
        } else {
            axios.post(`${urls.remoteURL}your-quote/${quoteID}/accept`, {
                signature, payment, commitment, additionalDays,
                company,
                signature_name,
                director_name,
                billing_name,
                billing_email,
                billing_telephone,
                billing_position,
                hs_contact_name,
                hs_email,
                hs_telephone,
                hs_title,
            })
                .then(() => {
                    toast.success('Quote updated')
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth' // Use smooth scrolling behavior
                    });
                    navigate(`/your-offer/${quoteID}/thank-you`);
                })
                .catch(({ response }) => {
                    toast.error(response.data.message || 'Error updating your quote. Please try again or contact technical support.')
                });
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            axios.post(`${urls.remoteURL}your-quote/${quoteID}/update`, {
                payment, commitment, additionalDays,
                signature_name,
                director_name,
                billing_name,
                billing_email,
                billing_telephone,
                billing_position,
                hs_contact_name,
                hs_email,
                hs_telephone,
                hs_title,
            })
                .then(() => {
                    // toast.success('Quote updated')
                })
                .catch(({ response }) => {
                    toast.error(response.data.message || 'Error updating your quote. Please try again or contact technical support.')
                });

        }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)

        return () => clearTimeout(timer); // Cleanup function clears the timer on unmount or on subsequent renders
    }, [payment, commitment, additionalDays, signature_name, director_name, billing_name, billing_email, billing_telephone, billing_position, hs_contact_name, hs_email, hs_telephone, hs_title])


    useEffect(() => {
        const timer = setTimeout(() => {
            if (!!company) {
                axios.post(`${urls.remoteURL}your-quote/${quoteID}/update-company`, { company })
                    .then(() => {
                        // toast.success('Quote updated')
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message || 'Error updating your company. Please try again or contact technical support.')
                    });
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [company])



    useEffect(() => {

        if (!company?.name?.trim() ||
            !company?.address?.trim() ||
            !company?.city?.trim() ||
            !company?.postcode?.trim() ||
            (!company?.additional_address && !!company?.sites && company?.sites > 0) ||
            !billing_email?.trim() ||
            !billing_name?.trim() ||
            !billing_position?.trim() ||
            !billing_telephone?.trim() ||
            !director_name?.trim() ||
            !hs_contact_name?.trim() ||
            !hs_email?.trim() ||
            !hs_title?.trim() ||
            !hs_telephone?.trim()
        ) {
            setRequired(true);
        } else {
            setRequired(false);
        }
    }, [company, billing_name, billing_email, billing_telephone, billing_position, director_name, agreed, hs_contact_name, hs_email, hs_telephone, hs_title])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Use smooth scrolling behavior
        });
    }, [currentStep])



    if (loading) {
        return (
            <LoadingDots />
        )
    }

    if (notFound) {
        return (
            <Error />
        )
    }

    return (
        <div className="">
            <div className="mb-3">
                <div className="block sm:flex print:flex justify-between p-4">
                    <img src={'/images/blue-logo.png'} className="order-none sm:order-last h-auto sm:h-20 md:h-32 print:h-20 print:order-last" />
                    <h1 className="text-5xl sm:text-4xl md:text-6xl xl:text-8xl">YOUR<br /><span className=" outline-text">PROPOSAL</span></h1>
                </div>
                <div className="block md:flex print:flex justify-between px-4">
                    <h4 className="font-light">We take care of your health and safety so that you don't have to</h4>
                    <div className="flex gap-2 print:hidden">
                        {admin &&
                            <>
                                <Button
                                    color="rhinoLightBlue"
                                    to={`../../companies/${company?.id}/quotes`}
                                >Company Quotes</Button>
                                <Button
                                    color="green"
                                    // disabled={send}
                                    onClick={handleSendQuote}
                                >Send Quote</Button>
                            </>
                        }
                        <Button
                            color="rhinoBlue"
                            to={`${urls.remoteURL}your-quote/${quoteID}/pdf`}
                            target="_blank"
                        >Print</Button>
                    </div>
                </div>
            </div>
            <div className="border-b-2 border border-rhinoBlue-600 mx-2 mb-3" />


            <div className="block md:flex print:flex">
                <div className="shrink mx-3">
                    {!!quote?.profile && <img className="h-16 w-16 object-cover rounded-full" src={`${urls.remoteURL}${quote.profile}`} alt={`${quote.firstname} ${quote.lastname}`} />
                    }
                </div>
                <div className="flex-1 mx-3">
                    <p className="text-3xl font-bold"><span className="font-light block md:inline-block">Offered for:</span> {company?.contact}</p>
                    <p className="text-3xl font-bold"><span className="font-light block md:inline-block">Prepared by:</span> {quote?.firstname} {quote?.lastname}</p>
                    {admin && (quote?.sent as number) > 0 &&
                        <p className="text-3xl font-bold"><span className="font-light block md:inline-block">Offer Sent:</span> {formatDate({ time: quote?.sent as number })}</p>
                    }

                </div>
                <div className="flex-1 mx-3">
                    <p className="text-3xl font-bold"><span className="font-light block md:inline-block">at:</span> {company?.name}</p>
                    <p className="text-3xl font-bold"><span className="font-light block md:inline-block">Valid until:</span> {formatDate({ time: quote?.validDate as number })}</p>
                </div>
            </div>


            <div className="mt-9 mb-3">
                <div className="block md:flex print:flex">
                    <div className="bg-gray-300 p-3 italic md:py-4 md:pl-6 md:pr-6">
                        <p className="sm:text-2xl">Making Safety Second Nature</p>
                    </div>
                </div>
            </div>
            <div
                className="flex items-start overflow-hidden w-96 sm:w-full"
                ref={wrapper}
            >
                <div className="flex flex-nowrap ">
                    <Transition
                        appear={false}
                        unmount={false}
                        show={currentStep === 0}
                        enter="transform transition ease-in-out duration-500"
                        enterFrom={
                            moving === "right"
                                ? `translate-x-96 opacity-0`
                                : `-translate-x-96 opacity-0`
                        }
                        enterTo={`translate-x-0 opacity-100`}
                        leave="transform transition ease-in-out duration-500 "
                        leaveFrom={`translate-x-0 opacity-100`}
                        leaveTo={
                            moving === "right"
                                ? `-translate-x-full opacity-0`
                                : `translate-x-full opacity-0`
                        }
                        className="w-0 overflow-visible"
                        as="div"
                    >
                        <div
                            style={{ width: `${wrapperWidth}px` }}
                        >
                            {
                                !quote?.payment_options.onetime &&
                                <h2 className="text-3xl md:text-6xl px-3"><span className="block lg:inline-block print:inline-block">Your Package:</span> <span className="outline-text">{quote?.package_options.options.filter((x: any) => x.id === quote?.package)[0].value}</span> {quote?.package_plus ? <FontAwesomeIcon icon={faBadgeCheck} fixedWidth className="align-middle inline h-fit w-8 md:w-14" /> : ''}</h2>
                            }



                            <div className="block md:flex print:block mt-4">
                                <div className="flex flex-1 bg-lightBlue-500">
                                    <div className="p-3">
                                        <h2 className="text-white font-bold">Included:</h2>
                                        <ul className="list-disc list-outside  font-medium ml-6 mt-4">
                                            {quote?.options?.map(option => {
                                                if (option.header) {
                                                    return <h3 key={option.id} className="text-white -ml-6 pl-0">{option.value}</h3>
                                                }
                                                return (
                                                    <li key={option.id} className="text-white">{option.value}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                {
                                    !!quote?.notes &&
                                    <div className="flex-1 md:border-b-2 md:border-rhinoBlue-600">
                                        <div className="p-3  ">
                                            <h2 className=" font-bold">Notes:</h2>
                                            {NewLineText({ text: quote.notes }).map(x => x)}
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* {quote?.additional_options?.daysrate > 0 &&
                                    <>
                                        <div className="mt-5 mb-3 p-3 text-center">
                                            <h2 className="text-2xl md:text-4xl mb-5 uppercase"> Additional monitoring days </h2>
                                            <p className="text-gray-500 text-xl">Add extra monitoring days to your package.</p>
                                        </div>
                                        <div className="max-w-xl mx-auto flex">
                                            <div className="mb-2 text-xl flex-1 place-self-center">{company?.currency} {numeral(quote?.additional_options?.daysrate).format('0,0.00')}<span className="text-lg"> + VAT per day</span></div>
                                            <div className=" place-self-center">
                                                <Select
                                                    options={[{ value: 0, label: 'Days' }, ...Array.from(Array(parseInt(quote.additional_options.days) || 20), (x, i) => ({ value: i + 1, label: i + 1 }))]}
                                                    disabled={loading || signed}
                                                    value={additionalDays}
                                                    className={'disabled:cursor-not-allowed'}
                                                    onChange={(e) => {
                                                        setAdditionalDays(e.target.value)
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </>} */}

                            <div className="mt-5 mb-3 p-3 text-center">
                                <Button
                                    type="button"
                                    color="rhinoBlue"
                                    // classes={["inline-flex items-center px-6 py-3  shadow-sm text-base font-medium rounded-md"]}
                                    onClick={() => { setMoving("right"); setCurrentStep(1); }}
                                >
                                    Continue
                                    <FontAwesomeIcon icon={faArrowRight} fixedWidth className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                                </Button>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div className="flex flex-nowrap ">
                    <Transition
                        appear={false}
                        unmount={false}
                        show={currentStep === 1}
                        enter="transform transition ease-in-out duration-500"
                        enterFrom={
                            moving === "right"
                                ? `translate-x-96 opacity-0`
                                : `-translate-x-96 opacity-0`
                        }
                        enterTo={`translate-x-0 opacity-100`}
                        leave="transform transition ease-in-out duration-500 "
                        leaveFrom={`translate-x-0 opacity-100`}
                        leaveTo={
                            moving === "right"
                                ? `-translate-x-96 opacity-0`
                                : `translate-x-96 opacity-0`
                        }
                        className=" w-0 overflow-visible"
                        as="div"
                    >
                        <div
                            style={{ width: `${wrapperWidth}px` }}
                        >
                            {!!quote?.payment_options.options &&
                                <>

                                    <RadioGroup value={commitment} onChange={(value) => { if (quote.status !== 'accepted') { setCommitment(value) } }} disabled={quote.status === 'accepted'}>
                                        <div className="mt-5 mb-3 p-3 text-center">
                                            <h2 className="text-2xl md:text-4xl mb-5 uppercase"> Your Commitment </h2>
                                        </div>
                                        <div className="space-y-4 max-w-xl mx-auto">
                                            {quote?.payment_options.options.map((option: any) => (
                                                <RadioGroup.Option
                                                    key={option.value}
                                                    value={option.id}
                                                    disabled={quote.status === 'accepted'}
                                                    className={({ checked, active }) =>
                                                        classNames(
                                                            signed ? 'cursor-not-allowed' : 'cursor-pointer',
                                                            checked ? 'border-transparent' : 'border-gray-300',
                                                            active ? 'border-rhinoBlue-500 ring-2 ring-rhinoBlue-500' : '',
                                                            'relative block  rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                                                        )
                                                    }
                                                >
                                                    {({ active, checked }) => (
                                                        <>
                                                            <span className="flex items-center">
                                                                <span className="flex flex-col text-sm">
                                                                    <RadioGroup.Label as="span" className="text-gray-900 text-4xl font-bold">
                                                                        {option.value}
                                                                    </RadioGroup.Label>
                                                                    <RadioGroup.Description as="span" className="text-gray-500 text-2xl font-bold">
                                                                        {company?.currency} {numeral(total as number * option.rate).format('0,0.00')}<span className="text-lg"> {!!quote.inc_vat && `+ VAT`} per year</span>
                                                                    </RadioGroup.Description>
                                                                </span>
                                                            </span>
                                                            <RadioGroup.Description
                                                                as="span"
                                                                className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col place-self-center sm:text-right"
                                                            >
                                                                {!!checked && <FontAwesomeIcon icon={faCheck} className="h-12 text-rhinoBlue-500" />}
                                                            </RadioGroup.Description>
                                                            <span
                                                                className={classNames(
                                                                    active ? 'border' : 'border-2',
                                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                                    'pointer-events-none absolute -inset-px rounded-lg'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    )}
                                                </RadioGroup.Option>
                                            ))}

                                        </div>
                                    </RadioGroup>
                                </>
                            }

                            {((quote?.discount_percent as number) > 0 || (quote?.discount_amount as number) > 0) &&

                                <>
                                    <div className="mt-5 mb-3 p-3">
                                        <h2 className="text-2xl md:text-4xl text-center mb-5 uppercase">Your Special Discount</h2>
                                        <div className="text-center">
                                            <h2 className="text-6xl md:text-8xl">{quote?.discount_amount ? <><span className="outline-text">{company?.currency}</span>{quote?.discount_amount}</> : <>{quote?.discount_percent}<span className="outline-text">%</span></>}</h2>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="mt-5 mb-3 p-3">
                                <h2 className="text-2xl md:text-4xl text-center mb-5 uppercase">So, what will it cost?</h2>

                                {
                                    !quote?.payment_options.onetime && !!currentCommitment ?


                                        <div className="block md:flex">
                                            <div className="flex-1 text-center">
                                                <h2 className="text-3xl md:text-6xl">{company?.currency} {numeral((total as number) * currentCommitment.rate).format('0,0.00')}</h2><h3>{!!quote?.inc_vat && `+ VAT`} per year</h3>
                                            </div>
                                            <div className="shrink text-center self-center"> <p className="text-xl font-black">or</p> </div>
                                            <div className="flex-1 text-center">
                                                <h2 className="text-3xl md:text-6xl">{company?.currency} {numeral((finalTotal as number) / currentCommitment.months || 12).format('0,0.00')}</h2><h3>{!!quote?.inc_vat && `+ VAT`} per month</h3>


                                            </div>
                                        </div>

                                        :
                                        <div className="block md:flex">
                                            <div className="flex-1 text-center">
                                                <h2 className="text-3xl md:text-6xl">{company?.currency} {numeral(finalTotal).format('0,0.00')}</h2><h3>{!!quote?.inc_vat && `+ VAT`}</h3>
                                            </div>
                                        </div>

                                }
                            </div>

                            {!!quote?.extra_cost_notes &&
                                <div className="mt-5 mb-3 p-3 text-center">
                                    <h2 className="text-xl md:text-3xl text-center mb-5 uppercase">Additional Costs</h2>
                                    {(quote?.extra_cost as number) > 0 &&
                                        <div className="block md:flex">
                                            <div className="flex-1 text-center">
                                                <h2 className="text-2xl md:text-5xl">{company?.currency} {numeral(quote.extra_cost).format('0,0.00')} {!!quote?.inc_vat && `+ VAT`}</h2>
                                            </div>
                                        </div>
                                    }
                                    <h3 className="text-1xl md:text-3xl mb-5">{quote.extra_cost_notes}</h3>

                                </div>
                            }


                            <div className="flex gap-2 justify-center mb-3 p-3 ">
                                <Button
                                    type="button"
                                    color="rhinoBlue"
                                    // classes={["inline-flex items-center px-6 py-3  shadow-sm text-base font-medium rounded-md"]}
                                    onClick={() => { setMoving("left"); setCurrentStep(0); }}
                                >
                                    Previous
                                    <FontAwesomeIcon icon={faArrowLeft} className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                                </Button>
                                <Button
                                    type="button"
                                    color="rhinoBlue"
                                    // classes={["inline-flex items-center px-6 py-3  shadow-sm text-base font-medium rounded-md"]}
                                    onClick={() => { setMoving("right"); setCurrentStep(2); }}
                                >
                                    Continue
                                    <FontAwesomeIcon icon={faArrowRight} fixedWidth className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                                </Button>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div className="flex flex-nowrap ">
                    <Transition
                        appear={false}
                        unmount={false}
                        show={currentStep === 2}
                        enter="transform transition ease-in-out duration-500"
                        enterFrom={
                            moving === "right"
                                ? `translate-x-96 opacity-0`
                                : `-translate-x-96 opacity-0`
                        }
                        enterTo={`translate-x-0 opacity-100`}
                        leave="transform transition ease-in-out duration-500 "
                        leaveFrom={`translate-x-0 opacity-100`}
                        leaveTo={
                            moving === "right"
                                ? `-translate-x-96 opacity-0`
                                : `translate-x-96 opacity-0`
                        }
                        className=" w-0 overflow-visible"
                        as="div"
                    >
                        <div
                            style={{ width: `${wrapperWidth}px` }}
                        >
                            <div className="mt-5 mb-3 p-3 text-center">
                                <h2 className="text-2xl md:text-4xl mb-5 uppercase">Want to go ahead?</h2>
                                <h3 className="text-1xl md:text-2xl mb-5">What does accepting this offer mean?</h3>
                                <p className="text-lg md:text-lg px-2 md:px-6 mb-5">Accepting this offer indicates that you are agreeing to the scope of work, price, and payment terms as detailed above, for the entirety of the period specified. Full Terms and Conditions can be found on the link below.</p>

                                <h3 className="text-lg font-normal md:text-2xl mb-5">Your Company:</h3>
                                <div className="mx-auto md:flex gap-4 justify-center">
                                    <div className="space-y-3 max-w-lg flex-1">
                                        <div className="text-left">
                                            <Label required>Company Name</Label>
                                            <Input
                                                type="text"
                                                disabled={quote?.status === 'accepted'}
                                                defaultValue={company?.name}
                                                required={true}
                                                invalid={!company?.name}
                                                // {...register('name', { required: true })}
                                                onChange={(e) => {
                                                    setCompany(prev => ({ ...prev, name: e.target.value }))
                                                }}
                                            />
                                            <p className="italic text-gray-600 text-sm">Please ensure the company name is legally accurate (e.g. as it appears on Companies House registration), as this is how it will appear in your policy, which is legal documentation.</p>
                                        </div>
                                        <div className="space-y-3 text-left">
                                            <div>
                                                <Label required={true}>Address</Label>
                                                <div className="space-y-2">
                                                    <Input
                                                        type="text"
                                                        disabled={quote?.status === 'accepted'}
                                                        defaultValue={company?.address}
                                                        // invalid={!!errors.address}
                                                        // {...register('address', { required: true })}
                                                        required={true}
                                                        onChange={(e) => {
                                                            setCompany(prev => ({ ...prev, address: e.target.value }))
                                                        }}
                                                    />
                                                    <Input
                                                        type="text"
                                                        disabled={quote?.status === 'accepted'}
                                                        defaultValue={company?.address_2}
                                                        // {...register('address_2')}
                                                        onChange={(e) => {
                                                            setCompany(prev => ({ ...prev, address_2: e.target.value }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <Label required={true}>City</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={company?.city}
                                                    required={true}
                                                    // invalid={!!errors.city}
                                                    // {...register('city', { required: true })}
                                                    onChange={(e) => {
                                                        setCompany(prev => ({ ...prev, city: e.target.value }))
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <Label>County</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={company?.county}
                                                    // {...register('county')}
                                                    onChange={(e) => {
                                                        setCompany(prev => ({ ...prev, county: e.target.value }))
                                                    }}
                                                />
                                            </div>
                                            <div className="flex gap-2">
                                                <div>
                                                    <Label required={true}>Postcode</Label>
                                                    <Input
                                                        type="text"
                                                        disabled={quote?.status === 'accepted'}
                                                        defaultValue={company?.postcode}
                                                        required={true}
                                                        // invalid={!!errors.postcode}
                                                        // {...register('postcode', { required: true })}
                                                        onChange={(e) => {
                                                            setCompany(prev => ({ ...prev, postcode: e.target.value }))
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <Label>Country</Label>
                                                    <Input
                                                        type="text"
                                                        disabled={quote?.status === 'accepted'}
                                                        defaultValue={company?.country}
                                                        // {...register('country')}
                                                        onChange={(e) => {
                                                            setCompany(prev => ({ ...prev, country: e.target.value }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                    <div className="space-y-3  max-w-lg flex-1">
                                        <div className="text-left">
                                            <Label>Addtional Premises</Label>
                                            <CustomSelect
                                                name="sites"
                                                disabled={quote?.status === 'accepted'}
                                                defaultValue={company?.sites}
                                                onChange={(e) => {
                                                    setCompany(prev => ({ ...prev, sites: e.target.value }))
                                                }}
                                                required
                                            >
                                                <option key='sites_none' value={0}>N/A</option>
                                                {Array.from({ length: 20 }, (_, index) => {
                                                    const optionValue = `${index + 1}`;
                                                    return (
                                                        <option key={`sites_${index}`} value={optionValue + 1}>
                                                            {optionValue}
                                                        </option>
                                                    );
                                                })}
                                            </CustomSelect>
                                        </div>

                                        <div className="text-left">
                                            <Label required={!!company?.sites && company?.sites > 0}>Additional Premises Addresses</Label>
                                            <TextArea
                                                disabled={quote?.status === 'accepted'}
                                                defaultValue={company?.additional_address}
                                                // {...register('additional_address')}
                                                required={!!company?.sites && company?.sites > 0}
                                                onChange={(e) => {
                                                    setCompany(prev => ({ ...prev, additional_address: e.target.value }))
                                                }}
                                                rows={4}
                                            />
                                            <p className="italic text-gray-600 text-sm">One premise address per line.</p>

                                        </div>

                                        <div className="text-left">
                                            {/* <h3 className="text-lg font-normal md:text-2xl mb-5">Select your business type:</h3> */}
                                            <Label required={true}>Select your business type</Label>
                                            <div className=" mx-auto">
                                                <CustomSelect
                                                    name="package"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={company?.business_type}
                                                    onChange={(e) => {
                                                        setCompany(prev => ({ ...prev, business_type: e.target.value }))
                                                    }}
                                                    required
                                                >
                                                    <option key='none' value={undefined}>Select Business Type...</option>
                                                    {businessTypes.map((option) => (
                                                        <option key={'businessType_' + option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                                </CustomSelect>
                                            </div>
                                            {company?.business_type === 'Other' &&
                                                <div>
                                                    <Label required={true}>Please enter your business type</Label>
                                                    <Input
                                                        type="text"
                                                        disabled={quote?.status === 'accepted'}
                                                        defaultValue={company?.business_type_other}
                                                        // {...register('country')}
                                                        required
                                                        onChange={(e) => {
                                                            setCompany(prev => ({ ...prev, business_type_other: e.target.value }))
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div className="text-left ">
                                            {/* <h3 className="text-lg font-normal md:text-2xl mb-5">Select your industry:</h3> */}
                                            <Label required={true}>Select your industry</Label>

                                            <div className="mx-auto">
                                                <CustomSelect
                                                    name="package"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={company?.industry}
                                                    onChange={(e) => {
                                                        setCompany(prev => ({ ...prev, industry: e.target.value }))
                                                    }}
                                                >
                                                    <option key='none' value={undefined}>Select Industry...</option>
                                                    {industryOptions.map((option) => (
                                                        <option key={'package' + option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                                </CustomSelect>
                                            </div>
                                            {company?.industry === 'Other' &&
                                                <div>
                                                    <Label required={true}>Please enter your industry</Label>
                                                    <Input
                                                        type="text"
                                                        disabled={quote?.status === 'accepted'}
                                                        defaultValue={company?.industry_other}
                                                        // {...register('country')}
                                                        required
                                                        onChange={(e) => {
                                                            setCompany(prev => ({ ...prev, industry_other: e.target.value }))
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <div className="text-left">
                                            <Label required={true}>Director Name</Label>
                                            <Input
                                                type="text"
                                                disabled={quote?.status === 'accepted'}
                                                defaultValue={quote?.director_name}
                                                onChange={(e) => setDirectorName(e.target.value)}
                                                required={true}
                                            // error={}
                                            // {...register('postcode', { required: true })}
                                            />
                                        </div>

                                    </div>


                                </div>

                                <div className="block md:flex justify-center gap-4">

                                    <div className="space-y-3 max-w-lg">
                                        <h3 className="text-lg font-normal md:text-2xl mt-5 mb-5">Primary Health &amp; Safety Contact:</h3>

                                        <div className="mx-auto md:flex gap-2 justify-center max-w-lg">
                                            <div className="flex-1 text-left ">
                                                <Label required>Name</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={quote?.hs_contact_name}
                                                    onChange={(e) => setHSContactName(e.target.value)}
                                                    required

                                                />
                                            </div>
                                            <div className="flex-1 text-left max-w-lg">
                                                <Label required>Email</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={quote?.hs_email}
                                                    onChange={(e) => setHSEmail(e.target.value)}
                                                    required

                                                />
                                            </div>
                                        </div>
                                        <div className="md:flex gap-2 mx-auto max-w-lg">

                                            <div className="flex-1 text-left">
                                                <Label required>Telephone</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={quote?.hs_telephone}
                                                    onChange={(e) => setHSTelephone(e.target.value)}
                                                    required

                                                />
                                            </div>
                                            <div className="flex-1 text-left">
                                                <Label required>Position</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={quote?.hs_title}
                                                    onChange={(e) => setHSTitle(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="space-y-3 max-w-lg">
                                        <h3 className="text-lg font-normal md:text-2xl mt-5 mb-5">Invoicing Details:</h3>

                                        <div className="mx-auto md:flex gap-2 justify-center max-w-lg">
                                            <div className="flex-1 text-left ">
                                                <Label required>Name</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={quote?.billing_name}
                                                    onChange={(e) => setBillingName(e.target.value)}
                                                    required

                                                // required={true}
                                                // error={}
                                                // {...register('postcode', { required: true })}
                                                />
                                            </div>
                                            <div className="flex-1 text-left max-w-lg">
                                                <Label required>Email</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={quote?.billing_email}
                                                    onChange={(e) => setBillingEmail(e.target.value)}
                                                    required

                                                // required={true}
                                                // error={}
                                                // {...register('postcode', { required: true })}
                                                />
                                            </div>
                                        </div>
                                        <div className="md:flex gap-2 mx-auto max-w-lg">

                                            <div className="flex-1 text-left">
                                                <Label required>Telephone</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={quote?.billing_telephone}
                                                    onChange={(e) => setBillingTelephone(e.target.value)}
                                                    required

                                                // required={true}
                                                // error={}
                                                // {...register('postcode', { required: true })}
                                                />
                                            </div>
                                            <div className="flex-1 text-left">
                                                <Label required>Position</Label>
                                                <Input
                                                    type="text"
                                                    disabled={quote?.status === 'accepted'}
                                                    defaultValue={quote?.billing_position}
                                                    onChange={(e) => setBillingPosition(e.target.value)}
                                                    required
                                                // required={true}
                                                // error={}
                                                // {...register('postcode', { required: true })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="text-lg md:text-lg px-2 md:px-6 mb-5"><Button link={true} to="https://www.rhinosafety.co.uk/wp-content/uploads/2022/04/Terms-for-the-Supply-of-Services-Rhino-Retainer-and-Adhoc-v3-Meged.pdf" target="_blank">Click here for our full terms and conditions</Button></p>

                                <div className="print:hidden">
                                    {!quote?.payment_options.onetime &&
                                        <div className="mb-3">
                                            <p className="text-sm text-gray-500">Please select your prefered payment method.</p>
                                            <div className="mt-4 mx-auto flex justify-center  gap-4">
                                                <div className="flex items-center ">
                                                    <input
                                                        id="payment-full"
                                                        name="payment"
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-rhinoBlue-600 focus:ring-rhinoBlue-500 cursor-pointer"
                                                        onChange={() => setPayment('full')}
                                                        checked={['full', 'yearly'].includes(payment as string)}
                                                        disabled={quote?.status === 'accepted'}
                                                    />
                                                    <label htmlFor="payment-full" className="ml-3">
                                                        <span className="block text-lg  font-medium text-gray-700 cursor-pointer">Up Front</span>
                                                    </label>
                                                </div>
                                                <div className="flex items-center ">
                                                    <input
                                                        id="payment-monthly"
                                                        name="payment"
                                                        onChange={() => setPayment('monthly')}
                                                        type="radio"
                                                        className="h-4 w-4 border-gray-300 text-rhinoBlue-600 focus:ring-rhinoBlue-500 cursor-pointer"
                                                        checked={!payment || payment === 'monthly'}
                                                        disabled={quote?.status === 'accepted'}
                                                    />
                                                    <label htmlFor="payment-monthly" className="ml-3">
                                                        <span className="text-lg block font-medium text-gray-700 cursor-pointer">Monthly</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="flex justify-center mb-3 text-center ">
                                        <Checkbox
                                            id="agreed"
                                            label="Agree to our terms &amp; conditions"
                                            disabled={quote?.status === 'accepted' || quote?.status === 'accepted'}
                                            defaultChecked={agreed || quote?.status === 'accepted'}
                                            setValue={(e) => setAgreed(e as boolean)}
                                        />
                                    </div>

                                    <div className="mb-3  max-w-[400px] mx-auto">
                                        <h3 className="text-lg font-normal md:text-xl">Signature Name</h3>

                                        <Input
                                            type="text"
                                            disabled={quote?.status === 'accepted'}
                                            defaultValue={quote?.signature_name}
                                            onChange={(e) => setSignatureName(e.target.value)}
                                        // required={true}
                                        // error={}
                                        // {...register('postcode', { required: true })}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        {!admin ?
                                            !!quote?.signature && quote.signature.includes('data:image/png;base64') ?
                                                <div className="text-center space-y-2">
                                                    <img src={quote.signature} className="border p-2 mx-auto" />
                                                    {quote?.status !== 'accepted' && <Button type="button" color="red" onClick={() => { setSignature(undefined); setSigned(false); setQuote(prev => ({ ...prev, signature: undefined })); }}>Reset</Button>}
                                                </div> :
                                                <Button
                                                    type="button"
                                                    color="blue"
                                                    disabled={admin || !agreed}
                                                    // classes={["inline-flex items-center px-6 py-3  shadow-sm text-base font-medium rounded-md"]}
                                                    onClick={handleSignOfferClick}
                                                >
                                                    Sign
                                                    <FontAwesomeIcon icon={faPencilAlt} fixedWidth className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                                                </Button>
                                            : quote?.signature ?
                                                <div className="text-center ">
                                                    <img src={quote.signature} className="border p-2 mx-auto" />
                                                </div> :
                                                ''}
                                        <p className="mt-2 italic font-medium">I confirm I have authority on behalf of the company to sign this contract.</p>
                                    </div>



                                    <div className="flex gap-2 justify-center pt-3">
                                        <Button
                                            type="button"
                                            color="rhinoBlue"
                                            // classes={["inline-flex items-center px-6 py-3  shadow-sm text-base font-medium rounded-md"]}
                                            onClick={() => { setMoving("left"); setCurrentStep(1); }}
                                        >
                                            Previous
                                            <FontAwesomeIcon icon={faArrowLeft} className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                                        </Button>
                                        {quote?.status === 'accepted' ?
                                            <></>
                                            :
                                            <Button
                                                type="button"
                                                color="green"
                                                // bgHoverColor="hover:bg-green-700"
                                                disabled={admin || !agreed || !signed || quote?.status === 'accepted' || required}
                                                // classes={["inline-flex items-center px-6 py-3  shadow-sm text-base font-medium rounded-md"]}
                                                onClick={handleAcceptOfferClick}
                                            >
                                                Accept Offer
                                                <FontAwesomeIcon icon={faCheck} fixedWidth className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
                                            </Button>
                                        }
                                    </div>
                                </div>

                                <div className="mt-5 mb-3 p-3 text-center">
                                    <p className="italic">These quotations include all reasonable travelling expenses and associated costs.</p>
                                    <p className="italic">Please do not hesitate to contact us if you have any queries or would like to discuss any aspect further.</p>

                                </div>

                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </div >
    )
}

export default ViewQuote;
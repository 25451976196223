export const travelOptions = [
    { value: 0, label: 'Less than 1 1/2 Hour' },
    { value: 1, label: '1 1/2 Hours' },
    { value: 2, label: '2 1/2 Hours' }
]

export const packageOptions = [
    { value: 0, label: 'Low Risk' },
    { value: 1, label: 'Medium Risk' },
    { value: 2, label: 'High Risk' }
]

export const commitmentOptions = [
    { value: 12, label: '1 Year' },
    { value: 24, label: '2 Year' },
    { value: 36, label: '3 Year' },
]


export const businessTypes = [
    { value: 'Sole Trader', label: 'Sole Trader' },
    { value: 'Partnership', label: 'Partnership' },
    { value: 'Limited', label: 'Limited' },
    { value: 'LLP', label: 'LLP' },
    { value: 'PLC', label: 'PLC' },
    { value: 'Charity', label: 'Charity' },
    { value: 'Other', label: 'Other' },
]

export const industryOptions = [
    // { value: 'Office', label: 'Office' },
    // { value: 'Childcare', label: 'Childcare' },
    // { value: 'Hospitality', label: 'Hospitality' },
    // { value: 'Warehousing', label: 'Warehousing' },
    
    { value: 'Corporate/Professional Services', label: 'Corporate/Professional Services' },
    { value: 'Construction/Contractor', label: 'Construction/Contractor' },
    // { value: 'Construction & Contractors', label: 'Construction & Contractors' },
    { value: 'Childcare/Education', label: 'Childcare/Education' },
    { value: 'Hospitality/Leisure', label: 'Hospitality/Leisure' },
    { value: 'Warehousing/Logistics', label: 'Warehousing/Logistics' },
    // { value: 'Engineering & Manufacturing', label: 'Engineering & Manufacturing' },
    { value: 'Engineering/Manufacturing', label: 'Engineering/Manufacturing' },
    { value: 'Retail/Commercial', label: 'Retail/Commercial' },
    { value: 'Healthcare/Wellness', label: 'Healthcare/Wellness' },
    { value: 'Transportation/Fleet Services', label: 'Transportation/Fleet Services' },


    // { value: 'Other', label: 'Other' },
]